function Features() {
    return (
        <div className='container mx-auto mt-40 max-w-9/10 sm:max-w-4/5 text-white pt-10' id='features'>
            <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex'>
                        <div className='w-1/2 pr-4'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Anime</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>Execute actions towards other people with randomized anime GIFs.</p>
                        </div>
                        <div className='w-full'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/cGpw79v.gif' alt='Anime' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex flex-col md:flex-row'>
                        <div className='w-full md:w-1/2 pr-4 md:order-2'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Configuration</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>Configure Celendi to your own likings, like customizing DMs sent to users when specific actions happen with support for tags and even more.</p>
                        </div>
                        <div className='w-full md:w-1/2'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/v5sqSZE.png' alt='Configuration' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex'>
                        <div className='w-1/2 pr-4'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Fun</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>Have some fun with Celendi, play games in Discord like snake, fasttype, guessthenumber and even more!</p>
                        </div>
                        <div className='w-full'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/8a7BN3c.png' alt='Fun' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex flex-col md:flex-row'>
                        <div className='w-full md:w-1/2 md:order-2'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Logging</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>Log everything that happens in your server. From moderation to role logs, all customizable per channel and multiple log channels per server are possible.</p>
                        </div>
                        <div className='w-full md:w-1/2 md:order-1'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/uBxq7PU.png' alt='Logging' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex'>
                        <div className='w-1/2 pr-4'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Information</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>View information about the server, users, emojis and more with our modern information commands.</p>
                        </div>
                        <div className='w-full'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/0AWSXma.png' alt='Information' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex flex-col md:flex-row'>
                        <div className='w-full md:w-1/2 md:order-2'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Memes</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>View random memes from Reddit or generate memes with our meme templates.</p>
                        </div>
                        <div className='w-full md:w-1/2 md:order-1'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/rcYNkto.png' alt='Memes' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex'>
                        <div className='w-1/2 pr-4'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Moderation</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>Moderate members and keep your server clean with our moderation and cases module.</p>
                        </div>
                        <div className='w-full'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/4EHNsyX.png' alt='Moderation' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex flex-col md:flex-row'>
                        <div className='w-full md:w-1/2 md:order-2'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Utility</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>Make things easier with our utility commands, create or steal stickers/emojis with just one command.</p>
                        </div>
                        <div className='w-full md:w-1/2 md:order-1'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/FCkXxCd.png' alt='Utility' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex'>
                        <div className='w-1/2 pr-4'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Welcomer</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>Set up a welcome/leave message, embed, rankcard or role that is fully customizable.</p>
                        </div>
                        <div className='w-full'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/yIqx7Yt.png' alt='Welcomer' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex flex-col md:flex-row'>
                        <div className='w-full md:w-1/2 md:order-2'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Starboard</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>Fully customizable starboard with custom emoji & more.</p>
                        </div>
                        <div className='w-full md:w-1/2 md:order-1'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/6i5vo9E.png' alt='Starboard' />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8'>
                    <div className='flex'>
                        <div className='w-1/2 pr-4'>
                            <h2 className='text-2xl font-bold mb-2 md:mb-4 text-center md:text-start'>Lounges</h2>
                            <p className='text-base md:text-lg mb-4 text-center md:text-start'>Let users create a lounge by joining a VC, customizable max users and name.</p>
                        </div>
                        <div className='w-full'>
                            <img className='rounded-xl w-11/12' src='https://i.imgur.com/LPlINMP.gif' alt='Lounges' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;
