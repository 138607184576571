import Navbar from './components/Navbar';
import Description from './components/Description';
import Stats from './components/Stats';
import Features from './components/Features';
import Footer from './components/Footer';

function App() {
    return (
        <>
            <Navbar />
            <Description />
            <Stats />
            <Features />
            <Footer />
        </>
    );
}

export default App;
